import React, {PropsWithChildren} from "react";
import {Flex} from "@chakra-ui/react";

type MenuItemProps = PropsWithChildren<{}>

export const MenuItem = ({children}: MenuItemProps) => {
  return (
    <Flex
      align="center"
      mx="4"
      px="4"
      py="4"
      rounded="md"
      cursor="pointer"
      _active={{
        bg: "gray.100",
      }}
      _hover={{
        bg: "gray.50",
      }}
      role="group"
      fontWeight="medium"
      transition=".15s ease"
    >
      {children}
    </Flex>
  );
};
