import React from 'react';
import './index.scss';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {OpenAPI} from "./openapi/core/OpenAPI";
import {createRoot} from 'react-dom/client';
import '@fontsource/roboto/500.css'
import {reconfigureRefreshInstance} from "./atoms/auth";
import {HelmetProvider} from 'react-helmet-async';


declare global {
  interface Window {
    config: {
      API_BASE_URL?: string
    };
  }
}

if (window.config?.API_BASE_URL) {
  OpenAPI.BASE = window.config.API_BASE_URL
  reconfigureRefreshInstance()
}

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "#F6F6F6",
      },
    }),
  },
  fonts: {
    body: 'Roboto, sans-serif',
  },
  colors: {
    gray: {
      '50': '#f9f9fa',
      '100': '#d0d0d6',
      '200': '#b2b2bc',
      '300': '#8c8c9b',
      '400': '#7c7c8d',
      '500': '#6d6d7d',
      '600': '#5f5f6d',
      '700': '#50505c',
      '800': '#42424c',
      '900': '#34343c',
      'A100': '#f1f1f8',
      'A200': '#ababd8',
      'A400': '#7676a7',
      'A700': '#737391',
    },
    primary: {
      '50': '#ffffff',
      '100': '#ffffff',
      '200': '#e4d7fd',
      '300': '#b594f8',
      '400': '#a277f7',
      '500': '#8e5af5',
      '600': '#7a3df3',
      '700': '#6620f1',
      '800': '#560ee5',
      '900': '#4b0cc8',
      'A100': '#ffffff',
      'A200': '#f0e9ff',
      'A400': '#ad83ff',
      'A700': '#9d6efa',
    },
    secondary: {
      '50': '#ffffff',
      '100': '#ffffff',
      '200': '#fde2da',
      '300': '#fbac96',
      '400': '#f99578',
      '500': '#f87e5b',
      '600': '#f7673e',
      '700': '#f55020',
      '800': '#ed3d0a',
      '900': '#d03509',
      'A100': '#ffffff',
      'A200': '#fff1ed',
      'A400': '#ffa287',
      'A700': '#fd8f70',
    },
  }
})
const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <App/>
        </ChakraProvider>
        <ReactQueryDevtools/>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
