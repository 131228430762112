import axios, {AxiosRequestConfig} from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {LoginResponse, OpenAPI} from "openapi/api/user-authorization";
import {BehaviorSubject} from "rxjs";
import {useObservable} from "../hooks/rxjsHooks";
import {useEffect} from "react";

export interface AuthTokens {
  accessToken?: string
  refreshToken: string
}

const readTokens = () => {
  const tokensJson = localStorage.getItem('auth');
  return tokensJson ? JSON.parse(tokensJson) as AuthTokens : null
}

const saveTokens = (tokens: AuthTokens | null) => {
  if (tokens === null) {
    localStorage.removeItem('auth')
  } else {
    localStorage.setItem('auth', JSON.stringify(tokens))
  }
  currentTokens$.next(tokens)
}

export const currentTokens$ = new BehaviorSubject<AuthTokens | null>(readTokens())

export const useAuthTokens = () => {
  const tokens = useObservable(currentTokens$)
  return {tokens: tokens || null, setTokens: saveTokens}
}

export const useIsLoggedIn = () => {
  const {tokens} = useAuthTokens()
  return !!tokens
}

const accessAuthLogic = (request: AxiosRequestConfig) => {
  request.headers = request.headers || {};
  let currentTokens = currentTokens$.value;
  if (currentTokens) {
    request.headers['Authorization'] = `Bearer ${currentTokens.accessToken}`;
  }
  return request;
};

let refreshInstance = axios.create({baseURL: OpenAPI.BASE});

export const reconfigureRefreshInstance = () => {
  refreshInstance = axios.create({baseURL: OpenAPI.BASE});
}

const goToLogin = () => {
  window.location.replace('/user/login')
}

const refreshAuthLogic = (failedRequest: any) => {
  const currentTokens = currentTokens$.value
  if (currentTokens) {
    return refreshInstance.get<LoginResponse>(`/user-authorization/session/${currentTokens.refreshToken}`)
      .catch(error => {
        saveTokens(null)
        goToLogin()
        throw 'No refresh token'
      })
      .then(response => saveTokens(response.data), () => saveTokens(null))
  } else {
    goToLogin()
    return Promise.reject('No refresh token')
  }
}

export const useInitAuthTokens = () => {
  useEffect(() => {
    const accessInterceptor = axios.interceptors.request.use(accessAuthLogic);
    const refreshInterceptor = createAuthRefreshInterceptor(axios, refreshAuthLogic)
    return () => {
      axios.interceptors.request.eject(accessInterceptor)
      axios.interceptors.response.eject(refreshInterceptor)
    }
  })
}

export const useLogOut = () => {
  return async () => {
    const tokens = currentTokens$.value
    if (tokens) {
      await refreshInstance.delete<void>(`/user-authorization/session/${tokens.refreshToken}`)
    }
    saveTokens(null)
  }
}
