import {useTranslation} from "react-i18next";
import {Box, IconButton} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEarth} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const LanguageSwitch = () => {
  const {i18n} = useTranslation()

  const toggleLocale = () => {
    if (i18n.language == 'en') {
      i18n.changeLanguage('pl_PL')
    } else {
      i18n.changeLanguage('en')
    }
  }

  return (
    <Box style={{margin: '0 0.5em'}}>
      <IconButton
        aria-label="toggle language"
        size='xs'
        icon={<FontAwesomeIcon icon={faEarth}/>}
        onClick={toggleLocale}
      />
    </Box>
  )
}
