/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponse } from '../models/LoginResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { StartPasswordResetRequest } from '../models/StartPasswordResetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAuthorizationService {

    /**
     * @param requestBody
     * @returns LoginResponse Tokens
     * @throws ApiError
     */
    public static login(
        requestBody: LoginRequest,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-authorization/session',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param refreshToken
     * @returns LoginResponse Tokens
     * @throws ApiError
     */
    public static refreshToken(
        refreshToken: string,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-authorization/session/{refreshToken}',
            path: {
                'refreshToken': refreshToken,
            },
        });
    }

    /**
     * @param refreshToken
     * @returns void
     * @throws ApiError
     */
    public static logout(
        refreshToken: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user-authorization/session/{refreshToken}',
            path: {
                'refreshToken': refreshToken,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static startPasswordReset(
        requestBody: StartPasswordResetRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-authorization/password-reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param passwordResetToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static resetPassword(
        passwordResetToken: string,
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-authorization/password-reset/{passwordResetToken}',
            path: {
                'passwordResetToken': passwordResetToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
            },
        });
    }

}
