import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import axios from "axios";

const fallbackLanguage = 'en'

const projectToken = "df8111d060c5489cb9e67ca818d33688";
const apiKey = "3410b198E5CcD66e954E34cAC161Fc7e5e4d2Df9C676730D";

const loadPath = `https://cdn.simplelocalize.io/df8111d060c5489cb9e67ca818d33688/_latest/{{lng}}`;
const addMissingTranslationsEndpoint = `https://api.simplelocalize.io/api/v1/translations`;

const axiosInstance = axios.create({})

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLanguage,
    backend: {
      loadPath: loadPath
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      // optional step
      const configuration = {
        headers: {
          'X-SimpleLocalize-Token': apiKey
        }
      };

      const requestBody = {
        content: [
          {
            key: key,
            language: fallbackLanguage,
            text: fallbackValue
          }
        ]
      }
      axiosInstance.post(addMissingTranslationsEndpoint, requestBody, configuration)
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n;
