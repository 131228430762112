import {To, useNavigate} from "react-router-dom";
import {Button} from "@chakra-ui/react";
import {ButtonProps} from "@chakra-ui/button/dist/declarations/src/button";

type LinkButtonProps = ButtonProps & { to: To }

export const LinkButton = ({to, ...props}: LinkButtonProps) => {
  const navigate = useNavigate()

  const onClickHandler = () => navigate(to);
  return (<Button {...props} onClick={onClickHandler}/>);
}
