import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomepageMainScene} from "./modules/Homepage/scenes/HomepageMainScene/HomepageMainScene";
import {useInitAuthTokens} from "./atoms/auth";
import {Helmet} from "react-helmet-async";

const AuthModule = React.lazy(() => import("./modules/Auth/AuthModule"))
const FormModule = React.lazy(() => import("./modules/Form/FormModule"))
const UserModule = React.lazy(() => import("./modules/User/UserModule"))
const InvitationModule = React.lazy(() => import("./modules/Invitation/InvitationModule"))

function App() {
  useInitAuthTokens()

  return (
    <>
      <Helmet defaultTitle="Forminder" titleTemplate={'%s - Forminder'}/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomepageMainScene/>}/>
          <Route path="auth/*" element={<Suspense fallback={<p>Loading</p>}><AuthModule/></Suspense>}/>
          <Route path="user/*" element={<Suspense fallback={<p>Loading</p>}><UserModule/></Suspense>}/>
          <Route path="form/*" element={<Suspense fallback={<p>Loading</p>}><FormModule/></Suspense>}/>
          <Route path="invitation/*" element={<Suspense fallback={<p>Loading</p>}><InvitationModule/></Suspense>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
