import React from "react";
import CallToActionWithIllustration from "./components/LandingPage";
import {HomepageLayout} from "components/layouts/TopNavbarLayout/HomepageLayout";

export const HomepageMainScene = () => {
  return (
    <HomepageLayout>
      <CallToActionWithIllustration/>
    </HomepageLayout>
  )
}
