import React, {ReactNode} from "react";
import {TopNavbarLayout} from "./TopNavbarLayout";
import {Link} from "react-router-dom";
import {CurrentUserProfile} from "./CurrentUserProfile";
import {MenuItem} from "../MainLayout/MenuItem";
import {useIsLoggedIn} from "atoms/auth";
import {HStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {LanguageSwitch} from "../../LanguageSwitch";

export const HomepageLayout = ({children}: { children?: ReactNode }) => {
  const {t} = useTranslation()
  const isLoggedIn = useIsLoggedIn()

  const loggedInItems = <HStack>
    <MenuItem>
      <Link to="/user/dashboard">Dashboard</Link>
    </MenuItem>
    <CurrentUserProfile/>
    <LanguageSwitch/>
  </HStack>
  const notLoggedInItems = <HStack>
    <MenuItem>
      <Link to={`/user/register`}>{t('navigation.register')}</Link>
    </MenuItem>
    <MenuItem>
      <Link to={`/user/login`}>{t('navigation.login')}</Link>
    </MenuItem>
    <LanguageSwitch/>
  </HStack>
  return (
    <TopNavbarLayout navbar={isLoggedIn ? loggedInItems : notLoggedInItems}>
      {children}
    </TopNavbarLayout>
  )
}
