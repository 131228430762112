import React, {PropsWithChildren, ReactNode} from "react";
import styled from "@emotion/styled";
import {LogoBox} from "../MainLayout/LogoBox";

const Layout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  alignContent: 'stretch',
});

const Navbar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E6E6E6',
  backgroundColor: 'white',
});

const Content = styled.div({
  flex: '1 6 auto',
  padding: '1rem',
});

export const TopNavbarLayout = ({navbar, children}: { navbar?: ReactNode, children?: ReactNode }) => {
  return (
    <Layout>
      <Navbar>
        <LogoBox/>
        {navbar}
      </Navbar>
      <Content>
        {children}
      </Content>
    </Layout>
  )
}
