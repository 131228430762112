import {useEffect, useState} from "react";
import {Observable} from "rxjs";

export function useObservable<T>(observable: Observable<T>): T | undefined {
  const [state, setState] = useState<T>()
  useEffect(() => {
    const subscription = observable.subscribe(next => setState(next))
    return () => subscription.unsubscribe()
  }, [observable])
  return state;
}