import {Box, Link} from "@chakra-ui/react";
import Logo from "../../../assets/logo.svg";
import React from "react";

export const LogoBox = () => {
  return (
    <Box mx="4" my="2">
      <Link href="/">
        <img src={Logo} alt="Forminder"/>
      </Link>
    </Box>
  );
};
