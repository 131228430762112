import {useQuery} from "react-query";
import {UserProfileData, UserProfileService} from "openapi/api/user-profile";
import {Avatar, Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";

const UserName = ({data}: { data: UserProfileData }) => {
  if (!!data.firstName && !!data.lastName) {
    return (
      <Flex direction="column">
        <Text as="span" fontWeight="bold">
          {data.firstName}&nbsp;{data.lastName}
        </Text>
        <Text as="span" fontSize="xs" fontWeight="light">
          {data.email}
        </Text>
      </Flex>
    )
  } else {
    return (
      <Text as="span" fontWeight="bold">
        {data.email}
      </Text>
    )
  }
}

const useCurrentUserProfileQuery = () => {
  return useQuery(['user', 'current', 'profile'], UserProfileService.getCurrentUserProfile)
}

export const CurrentUserProfile = () => {
  const navigate = useNavigate()
  const {data, isLoading} = useCurrentUserProfileQuery();
  if (!data || isLoading) {
    return <>loading</>
  }

  return <Flex align="center" gap="2" mx="2">
    <UserName data={data}/>
    <Box width="10" height="10">
      <Avatar onClick={() => navigate('/user/logout')} size="full"/>
    </Box>
  </Flex>
}
